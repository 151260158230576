import React from "react";
import {
	useFetcher,
	useLocation,
	useParams,
	useRevalidator,
	useRouteLoaderData,
} from "@remix-run/react";

{
	/*{
    //user,
    //setNotifCall,
    //setIsTypingCall,
    //setNotifDeleteCall,
    //setNotifReloadCall,
  }*/
}
import { type loader as RootLoader } from "#app/root";
import { initPubnub } from "#app/utils/pubnub.ts";
import { useMatchesIndex } from "#app/utils/matches.ts";
import { useCacheFetcher } from "#app/utils/cache-client.tsx";

type SignalMessage = {
	actualChannel: string;
	message: any;
	timetoken: string | number;
	messageType?: string | number | undefined;
	uuid?: string | undefined;
};

let typingTimer: NodeJS.Timeout;
export const PubNubMain = React.memo(() => {
	const { user } = useRouteLoaderData<typeof RootLoader>("root") || {};
	if (!user) return;
	const pubnub = initPubnub(user);
	// const { roomId } = useParams();
	const revalidator = useRevalidator();
	const params = useParams();
	const fetcher = useFetcher();
	const fetcherList = useCacheFetcher({ key: "list-chat" });

	const location = useLocation();
	const fetcherMessages = useFetcher({
		key: params?.roomId,
	});

	const match = useMatchesIndex(2);

	React.useEffect(() => {
		// Periksa jika `user` valid

		// Langganan ke saluran dan simpan fungsi `unsubscribe`
		const unsubscribe = initPubnubClient();

		// Fungsi pembersihan dipanggil saat komponen di-unmount
		return () => {
			// Panggil fungsi `unsubscribe` untuk menghentikan langganan
			unsubscribe();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // Tambahkan `user` ke array ketergantungan jika relevan

	const initPubnubClient = () => {
		// Implementasi langganan ke saluran
		console.log("Subscribed to channels");
		// fetcher.load('/api/pubnub-store');
		subscribeChannels();

		// Kembalikan fungsi `unsubscribe` untuk menghentikan langganan
		return () => {
			console.log("Unsubscribed from channels");
			unsubscribeChannels(subscribeChannels());
		};
	};

	const unsubscribeChannels = (listener) => {
		pubnub.removeListener(listener);
		pubnub.unsubscribe({
			channels: ["private.*", "group.*", "chat-group.*"],
		});
	};

	const subscribeChannels = async () => {
		pubnub.subscribe({
			channels: ["private.*", "group.*", "chat-group.*"],
			withPresence: true,
		});

		// const userUUID = await pubnub.objects.getUUIDMetadata();

		const listener = {
			signal(sig) {
				if (sig?.message === "delete_all") {
				} else if (sig?.message === "reload_msg") {
				} else {
					if (sig.publisher !== user.uuid) {
						const container = document.getElementById(
							"container-typing-indicator",
						);
						if (container instanceof HTMLDivElement) {
							if (sig.message === "typing_off") {
								container.style.display = "none";
							} else {
								container.style.display = "flex";
							}

							const typingIndicator =
								container.querySelector("#typing-indicator");
							if (typingIndicator instanceof HTMLDivElement) {
								typingIndicator.innerHTML = sig.message;
							}
						}
					}
				}
			},
			message(msg: SignalMessage) {
				if (match) {
					typingTimer = setTimeout(() => {
						fetcherList.submit(
							{ intent: "pb" },
							{ method: "get", action: "/resources/channels" },
						);

						const decoded = decodeURIComponent(params.roomId as string);
						const [_roomId, _id] = decoded.split("|");
						if (_roomId !== msg.actualChannel) {
							// render notif
						}
						// fetcherMessages.submit(
						// 	{ channel: msg.actualChannel, invalidate: "true" },
						// 	{ method: "get", action: "/resources/messages" },
						// );
					}, 100);
				}

				// const message = {
				// 	channel: msg.actualChannel,
				// 	timetoken: msg.timetoken,
				// 	message: msg.message,
				// 	messageType: -1,
				// 	uuid: msg.publisher,
				// };
				//
				// const payload = {
				// 	intent: "new-message",
				// 	message: JSON.stringify(message),
				// };
				//
				// fetcher.submit(payload, {
				// 	method: "post",
				// 	action: match?.pathname,
				// });
				//console.log(msg);
				// if (msg.publisher !== user.uuid) {
				// 	revalidator.revalidate();
				// }
				//setNotifCall(msg);
			},
		};
		pubnub.addListener(listener);
		return listener;
	};

	return <div className="hidden">PUBNUB</div>;
});
